<template>
    <div class="project-index-page">
        <full-loading ref="fullLoading"></full-loading>
        <div class="page-tools-content" style="margin: 0;">
            <div class="left">
                <el-button type="primary" size="small" round icon="el-icon-plus" v-permission="`project:new`" @click="createProject">{{$t('project.project_index_page_btn_1')}}</el-button>
                <el-button size="small" plain round icon="el-icon-refresh-left" :loading="refreshLoading" style="margin-left:10px;" @click="initPage('reload')">{{$t('project.project_index_page_btn_2')}}</el-button>
                <router-link v-permission="`project:detailRouter:taskStatistics`" to="/project/taskStatistics" class="el-button el-button--default el-button--small is-plain is-round margin-l-10">{{$t('project.project_index_page_btn_3')}}</router-link>
            </div>
            <div class="right">
                <div style="width:200px;">
                    <el-input :placeholder="$t('project.project_index_page_search_input_placeholder')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <div class="margin-l-10" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <el-popover placement="bottom" trigger="click">
                        <div class="more-filter-content">
                            <el-select size="small" :placeholder="$t('project.project_index_page_search_select_placeholder_1')" filterable clearable v-model="searchForm.source" @change="selectSearchEvent" style="width: 150px;">
                                <template v-for="(item,key) in LANGUAGE_DICT">
                                    <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.target === item.key"></el-option>
                                </template>
                            </el-select>
                            <el-select size="small" :placeholder="$t('project.project_index_page_search_select_placeholder_2')" filterable clearable v-model="searchForm.target" @change="selectSearchEvent" class="margin-t-10" style="width: 150px;">
                                <template v-for="(item,key) in LANGUAGE_DICT">
                                    <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.source === item.key"></el-option>
                                </template>
                            </el-select>
                            <el-select v-model="searchForm.teamId" size="small" clearable :placeholder="$t('project.project_index_page_search_select_placeholder_3')" @change="selectSearchEvent" class="margin-t-10" style="width: 150px;">
                                <template v-for="item in teamOptions">
                                    <el-option :label="item.text" :value="item.value" :key="item.value"></el-option>
                                </template>
                            </el-select>
                            <template v-if="userInfo.internal">
                                <el-select v-model="activeFilter" size="small" clearable :placeholder="$t('project.project_index_page_search_select_placeholder_4')" @change="selectSearchEvent" class="margin-t-10" style="width: 150px;">
                                    <template v-for="item in statusOptions">
                                        <el-option :label="item.text" :value="item.value" :key="item.value"></el-option>
                                    </template>
                                </el-select>
                            </template>
                            <el-select v-model="searchForm.creator" size="small" clearable :placeholder="$t('project.project_index_page_search_select_placeholder_5')" filterable @change="selectSearchEvent" class="margin-t-10" style="width: 150px;">
                                <template v-for="item in memberOptions">
                                    <el-option :label="item.memberName" :value="item.memberId" :key="item.memberId"></el-option>
                                </template>
                            </el-select>
                        </div>
                        <el-badge slot="reference" is-dot class="more-filter-badge" :hidden="!(activeFilter || searchForm.teamId || searchForm.creator || searchForm.source || searchForm.target)">
                            <el-button  plain round size="small" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-funnel"></svg-icon>
                            </el-button>
                        </el-badge>
                    </el-popover>
                </div>
                <template v-if="view === 'list'">
                    <div class="margin-l-10" v-permission="`project:download`">
                        <el-dropdown placement="bottom" @command="(command)=>batchDownloadBtnEvent(command)">
                            <el-button plain round size="small" :loading="batchDownloadLoading" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-download-1" v-if="!batchDownloadLoading"></svg-icon>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="target" v-permission="`project:download`">{{$t('project.project_index_page_search_dropdown_item_1')}}</el-dropdown-item>
                                <el-dropdown-item command="bilingual" v-permission="`project:download`">{{$t('project.project_index_page_search_dropdown_item_2')}}
                                    <pro-icon :size="14" style="margin-bottom:-2px;margin-left:5px;" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1"></pro-icon>
                                </el-dropdown-item>
                                <el-dropdown-item command="source" v-permission="`project:download`">{{$t('project.project_index_page_search_dropdown_item_3')}}</el-dropdown-item>
                                <el-dropdown-item command="tmx" v-permission="`project:download`">{{$t('project.project_index_page_search_dropdown_item_4')}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="margin-l-10" v-permission="`project:detailRouter:delete`">
                        <el-tooltip :content="$t('project.project_index_page_search_tips_1')" placement="top">
                            <el-button plain round size="small" @click="batchDeleteBtnEvent" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-trash-o-1"></svg-icon>
                            </el-button>
                        </el-tooltip>
                    </div>
                </template>
                <div class="margin-l-10" v-permission="`project:detailRouter:delete`">
                    <el-tooltip :content="`${$t('project.project_index_page_search_changeView_tips_1')}${view === 'card' ? $t('project.project_index_page_search_changeView_tips_3') : $t('project.project_index_page_search_changeView_tips_2')}${$t('project.project_index_page_search_changeView_tips_4')}`" placement="top">
                        <el-button plain round size="small" @click="changeViewEvent" style="padding: 6.4px; font-size: 18px;">
                            <svg-icon name="ib-list" v-if="view === 'card'"></svg-icon>
                            <svg-icon name="ib-card" v-if="view === 'list'"></svg-icon>
                        </el-button>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <!-- <el-tabs class="filter-tabs" v-model="activeFilter" @tab-click="filterClickEvent" v-if="userInfo.internal">
          <el-tab-pane label="全部" name="ALL"></el-tab-pane>
          <el-tab-pane label="进行中" name="1"></el-tab-pane>
          <el-tab-pane label="已验收" name="2"></el-tab-pane>
        </el-tabs> -->
        <template v-if="list.length > 0">
            <card-list :list="list" @action="executeAction" style="margin-top: 30px;" v-if="view === 'card'"></card-list>
            <table-list ref="projectTableList" :list="list" @action="executeAction" v-else-if="view === 'list'"></table-list>
        </template>
        <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info" v-if="queryType === 'search'">{{$t('project.project_index_page_empty_tips_1')}}</div>
                <div class="info" v-else>{{$t('project.project_index_page_empty_tips_2')}}</div>
            </div>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="$t('project.project_index_page_dialog_1_title')" :visible.sync="checkDialog.show" destroy-on-close width="420px" top="20px">
            <div class="text-center fonts-15">{{$t('project.project_index_page_dialog_1_message')}}</div>
            <div slot="footer" class="text-center">
                <el-button plain round size="small" @click="checkDialog.show = false">{{$t('project.project_index_page_dialog_1_btn_1')}}</el-button>
                <el-button round size="small" type="primary" :loading="checkDialog.loading" @click="confirmCheck">{{$t('project.project_index_page_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteProject"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import FullLoading from '@/components/FullLoading';
import AlertDialog from '@/components/AlertDialog';
import { orgApi, projectApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
import CardList from './components/ProjectCardList.vue';
import TableList from './components/ProjectTableList.vue';
import ProIcon from "@/components/icon/ProIcon";
export default {
    components:{
        CardList, AlertDialog, FullLoading, TableList, ProIcon
    },
    data(){
        return {
            view: 'card', // 'list'
            pageTimer: null,
            refreshLoading:false,
            alertInfo:{
                message: this.$t('project.project_index_page_data_alertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('project.project_index_page_data_alertInfo_buttonName'),
                buttonType:'danger',
            },
            searchForm:{
                kw:'',
                source:'',
                target:'',
                teamId: '',
                creator: '',
                order: 'create_time,desc',
            },
            teamOptions: [],
            creatorOptions: [],
            memberOptions: [],
            selectedProjectIds:[],
            statusOptions: [
                {value: '1', text: this.$t('project.project_index_page_data_statusOptions_1_text')},
                {value: '2', text: this.$t('project.project_index_page_data_statusOptions_2_text')},
            ],
            orderOptions: [
                {value: 'name,asc', text: this.$t('project.project_index_page_data_orderOptions_1_text')}, 
                {value: 'create_time,desc', text: this.$t('project.project_index_page_data_orderOptions_2_text')}
            ],
            activeFilter: '1',
            queryType:'init',
            list:[],
            pageNumber: 1,
            pageSize: 16,
            total:0,
            checkDialog: {
                show: false,
                loading: false,
                param: {},
            },
            selectedItem: [],
            batchDownloadLoading: false,
        }
    },
    computed:{
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods: {
        initPage(type){
            let self = this;
            if(type === 'init'){
                this.$refs.fullLoading.setShow();
            }
            if(type === 'reload'){
                this.refreshLoading = true;
            }
            let url = `${projectApi.queryProjectList}?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}` +
                        `&keyword=${encodeURI(this.searchForm.kw)}` +
                        `&sourceLang=${this.searchForm.source}` +
                        `&targetLang=${this.searchForm.target}` + 
                        `&state=${this.activeFilter === 'ALL' ? '' : this.activeFilter}` +
                        `&teamId=${this.searchForm.teamId ? this.searchForm.teamId : ''}` + 
                        `&memberId=${this.searchForm.creator ? this.searchForm.creator : ''}` +
                        `&orders=${this.searchForm.order ? this.searchForm.order : ''}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let list = [];
                    res.data.pager.records.map(item => {
                        list.push({
                            ...item,
                            targetName: item.targetLangs.map(lang => lang.name).join('/'),
                            targetShortName: item.targetLangs.map(lang => lang.shortName).join('/'),
                        })
                    });
                    this.list = list;
                    this.total = res.data.pager.total;
                    if(this.pageTimer){
                        clearInterval(this.pageTimer);
                        this.pageTimer = null;
                    }
                    this.pageTimer = setInterval(()=>{
                        self.pollingProjectStatus();
                    },5000);
                }
            }).finally(()=>{
                this.$refs.fullLoading.setHide();
                this.refreshLoading = false;
            })
        },
        createProject(){
            if (this.userInfo.organizationType === 'ENTERPRISE') {
                this.$router.push('/project/create');
            }else {
                this.$router.push('/project/new');
            }
        },
        inputSearchEvent(value){
            let self = this;
            if(value.length > 0){
                this.queryType = 'search';
            }else{
                this.queryType = 'init';
            }
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(()=>{
                this.pageNumber = 1;
                self.initPage();
            },500);
        },
        handleCurrentChange(val){
            this.pageNumber = val;
            this.initPage();
        },
        selectSearchEvent(){
            this.pageNumber = 1;
            this.initPage();
        },
        batchDownloadBtnEvent(type){
            if(type === 'bilingual'){
                if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType)>-1){
                    this.$router.push('/account/vip');
                    return;
                }
            }
            if(this.selectedItem.length === 0) {
                this.$message.error(this.$t('project.project_index_page_methods_batchDownloadBtnEvent_message_1'));
                return;
            }
            let ids = [];
            this.selectedItem.map(item => {
                ids.push(item.projectId);
            });
            this.batchDownloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: ids.length === 1 ? this.selectedItem[0].name : this.$t('project.project_index_page_methods_batchDownloadBtnEvent_message_2', {size: ids.length}),
                metadataIdList: ids,
                metadataType: "TMS_PROJECT",
                moreOptions: {
                    exportFileType: type,
                }
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                    this.$refs.projectTableList.resetTableSelected();
                }
            }).finally(()=>{
                this.batchDownloadLoading = false;
            })
        },
        downloadResult(params){
            if(params.type === 'bilingual'){
                if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType)>-1){
                    this.$router.push('/account/vip');
                    return;
                }
            }
            this.downloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: params.project.name,
                metadataIdList: [params.project.projectId],
                metadataType: "TMS_PROJECT",
                moreOptions: {
                    exportFileType: params.type,
                }
            };
            this.$ajax.post(url, postData).then(res=>{
                // this.$message.success('导出任务已创建，导出成功后即可下载。');
                this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
            }).finally(()=>{
                this.downloadLoading = false;
            })
        },
        
        batchDeleteBtnEvent(){
            if(this.selectedItem.length === 0) {
                this.$message.error(this.$t('project.project_index_page_methods_batchDeleteBtnEvent_message_1'));
                return;
            }
            let ids = [];
            this.selectedItem.map(item => {
                ids.push(item.projectId);
            });
            this.selectedProjectIds = ids;
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteProject(){
            let url = projectApi.deleteProjects;
            this.$ajax.post(url,{
                ids:this.selectedProjectIds,
            }).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('project.project_index_page_methods_deleteProject_message_1'));
                    this.initPage();
                }
            })
        },
        retryTask(params){
            let url = this.$ajax.format(projectApi.retryTask, {id: params.projectId});
            this.$ajax.post(url, {}).then(res=>{
                if(res.status === 200){
                    if(res.data){
                        this.$message.success(this.$t('project.project_index_page_methods_retryTask_message_1'));
                        this.initPage();
                    }
                }
            })
        },
        confirmCheck() {
            let url = projectApi.checkAndAcceptProject;
            this.checkDialog.loading = true;
            this.$ajax.post(url, {projectId: this.checkDialog.param.projectId}).then(res => {
                if(res.status === 200) {
                    this.$message.success(this.$t('project.project_index_page_methods_confirmCheck_message_1'));
                    this.checkDialog.show = false;
                    this.initPage();
                }
            }).finally(() => {
                this.checkDialog.loading = false;
            });
        },
        saveSetting2Template (params) {
            // TODO 保存为模板
            let url = `${projectApi.creataTemplateFromProject}?projectId=${params.projectId}`;
            this.$ajax.post(url, {}).then(res => {
                if(res.status === 200) {
                    this.$message.success(this.$t('project.project_index_page_methods_saveSetting2Template_message_1'));
                }
            })
        },
        executeAction(key, params){
            switch (key){
                case 'download':
                    this.downloadResult(params);
                    break;
                case 'check':
                    this.checkDialog.param = params;
                    this.checkDialog.show = true;
                    break;
                case 'delete':
                    this.$refs.alertDialogDom.setShow(true);
                    this.selectedProjectIds = [params.projectId];
                    break;
                case 'goto':
                    if (!this.$checkPermission('project:detail')){
                        this.$message.error(this.$t('project.project_index_page_methods_executeAction_message_1'));
                        return;
                    }
                    this.$router.push({path:'/project/list',query:{id:params.projectId}});
                    
                    break;
                case 'retry':
                    this.retryTask(params);
                    break;
                case 'updateSelected':
                    this.selectedItem = params;
                    break;
                case 'template':
                    this.saveSetting2Template(params);
                    break;
            }
        },
        filterClickEvent(tab, event){
            this.pageNumber = 1;
            this.initPage('reload');
        },
        pollingProjectStatus() {
            let ids = [];
            this.list.map(item => {
                if (item.preprocessStatus !== "completed"){
                    ids.push(item.projectId);
                }
            });
            if(ids.length > 0) {
                let url = projectApi.pollingProjectStatus;
                this.$ajax.post(url, {projectIds: ids}).then(res => {
                    if (res.status === 200) {
                        let statusData = {};
                        res.data.map(item=>{
                            statusData[item.projectId] = item;
                        });
                        let _list = this.list;
                        _list.map((item, index) => {
                            if(statusData[item.projectId]){
                                this.list.splice(index, 1, {...item, ...statusData[item.projectId]})
                            }
                        });
                    }
                });
            }
        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.teamOptions = res.data.teamFilters;
                }
            });
        },
        queryCreatorOptions() {
            let url = orgApi.queryOrgMemberManagers;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.memberOptions = res.data;
                }
            })

        },
        changeViewEvent() {
            if(this.view === 'card') {
                this.view = 'list';
            }else if(this.view === 'list') {
                this.view = 'card';
            }
            this.$ls.set('projectView', this.view);
        },
    },
    created() {
        let _view = this.$ls.get('projectView');
        if(_view){
            this.view = _view;
        }else {
            if(this.userInfo.internal) {
                this.view = 'list';
            }
        }
    },
    mounted() {
        this.initPage('init');
        if (this.userInfo.organizationType === 'ENTERPRISE') {
            this.queryTeamOptions();
            this.queryCreatorOptions();
        }
        
    },
    beforeDestroy() {
        clearInterval(this.pageTimer);
        this.pageTimer = null;
    },
}
</script>
<style lang="scss" scoped>
.project-index-page{
    // padding:20px;
    
}
.more-filter-content {
    display: flex;
    flex-direction: column;
    ::v-deep .el-select {
        .el-input__inner {
            border-radius: 20px;
        }
    }
}
.more-filter-badge {
    ::v-deep .el-badge__content.is-dot {
        top: 5px;
        right: 8px;
    }
}
</style>
